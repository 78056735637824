import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2579272825/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "withpayload"
    }}>{`withPayload`}</h1>
    <p>{`The function `}<inlineCode parentName="p">{`withPayload`}</inlineCode>{` is used to generate an action creator with a payload. It can be called in two different ways -
with a single payload item or with a series of them:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Utils } from '@kineticdata/bundle-common';

// With a single payload item.
const setData = Utils.withPayload('SET_DATA');
setData('data'); // returns { type: 'SET_DATA', payload: 'data' }

// With multiple payload items:
const setMultiData = Utils.withPayload('SET_MULTI_DATA', 'first', 'second');
setMultiData(1, 1); // returns { type: 'SET_MULTI_DATA', payload: { first: 1, second: 2 } }
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      